import React, { useRef } from "react";
import { RemoteTable } from "@package/table";
import { useLoadTableData } from "@src/javascripts/common/useTableCallback";
import { Button, Space, Switch, message } from "antd";
import { RenderIcon } from "@package/icons";
import Edit from "./edit";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EnumUserRole } from "@src/javascripts/common/enums/EnumRole";
import { ResponseCode } from "@src/global/layout";
import { useDispatchAsync } from "@src/javascripts/common/useDispatch";

const UserList = () => {
  const [table] = RemoteTable.useTable();
  // const userInfo = useSelector((state: any) => state?.global?.userInfo || {});
  const _ref = useRef<any>();
  const navigate = useNavigate();

  const _columns = [
    {
      title: "ID",
      dataIndex: "userId",
    },
    {
      title: "用户名",
      dataIndex: "username",
      filter: true,
    },
    {
      title: "邮箱账号",
      dataIndex: "email",
    },
    {
      title: "账户剩余次数",
      dataIndex: "userBalanceList",
      render: (data) => {
        return (
          <Space direction="vertical">
            {data.map((item) => {
              return `${item.detailName}【${item.quantity}次】`;
            })}
          </Space>
        );
      },
    },
    {
      title: "手机号",
      dataIndex: "tel",
    },
    {
      title: "角色",
      dataIndex: "userRole",
      render: (data) => {
        return EnumUserRole[data] || data;
      },
    },
    {
      title: "状态",
      dataIndex: "userStatusName",
      render: (data, record) => {
        return (
          <Switch
            checked={record.userStatus}
            checkedChildren="启用"
            unCheckedChildren={"禁用"}
            onChange={async () => {
              const _res = await useDispatchAsync({
                type: "user/UpdateUser",
                data: {
                  ...record,
                  userStatus: record.userStatus == 0 ? 1 : 0,
                  userStatusName: record.userStatus == 0 ? "启用" : "禁用",
                },
              });

              if (_res.code === ResponseCode.Success) {
                message.success("用户信息保存成功");
                table?.reload?.();
                return;
              }

              return _res.message;
            }}
          ></Switch>
        );
      },
    },
    {
      title: "注册时间",
      dataIndex: "createTime",
      render: (data) => {
        return data ? dayjs(data).format("YYYY年MM月DD日") : "----";
      },
    },
    {
      title: "最近登录时间",
      dataIndex: "lastLoginTime",
      render: (data) => {
        return data ? dayjs(data).format("YYYY年MM月DD日") : "----";
      },
    },
    {
      title: "操作",
      render: (data, record) => {
        return (
          <Space>
            <Button
              type="link"
              onClick={() => editUser(record)}
              icon={<RenderIcon type="EditOutlined"></RenderIcon>}
            >
              编辑
            </Button>
            <Button
              type="link"
              onClick={() => navigate(`/user/works/${record.userId}`)}
              icon={<RenderIcon type="EyeOutlined"></RenderIcon>}
            >
              查看作品
            </Button>
          </Space>
        );
      },
    },
  ];

  const editUser = (data?: any) => {
    _ref.current.setVisible(true);

    if (data?.userId) {
      _ref.current.setFieldsValue(data);
    }
  };

  return (
    <>
      <RemoteTable
        table={table}
        columns={_columns}
        rowKey={"userId"}
        scroll={{ x: true }}
        loadData={useLoadTableData("user/UserList")}
        actionBar={
          <Button
            type="primary"
            icon={<RenderIcon type="PlusOutlined"></RenderIcon>}
            onClick={() => editUser()}
          >
            添加用户
          </Button>
        }
      ></RemoteTable>
      <Edit ref={_ref} table={table}></Edit>
    </>
  );
};

export default UserList;
