import { IPageModel } from "@package/store";

export default {
  namespace: "user",
  actionType: {
    Post: {
      UserList: "/model/user/listUser",
      AddUser: "/model/user/register",
      UpdateUser: "/model/user/register",
      UserWorks: "/model/user/listWork",
    },
    Get: {},
  },
} as IPageModel;
