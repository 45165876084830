import React, { Suspense, useMemo } from "react";
import { Navigate, Outlet, Route, useRoutes } from "react-router-dom";
import Page404 from "@js/components/Error/404";
import ErrorBoundary from "@src/javascripts/components/ErrorBoundary";
import routers from "@js/pages";
import { MainLayout } from "@js/components/Layout";
import { cloneDeep } from "lodash-es";
import { Spin } from "antd";
import classNames from "classnames";
import { motion } from "framer-motion";

let ElementContainer = ({ layout, scrollable, children }) => {
  if (layout == false) return <>{children}</>;

  return (
    <div
      className={classNames(
        "route-content-wrapper",
        scrollable === false ? "route-content-noscroll" : ""
      )}
    >
      {children}
    </div>
  );
};

const _wrapperElement = (route) => {
  const { element, layout, scrollable } = route;

  if (typeof element === "function") {
    const Element = route.lazy === false ? element : React.lazy(element);

    return (
      <ErrorBoundary>
        <ElementContainer scrollable={scrollable} layout={layout}>
          <Element />
        </ElementContainer>
      </ErrorBoundary>
    );
  }

  //@ts-ignore
  return (
    <ErrorBoundary>
      <ElementContainer scrollable={scrollable} layout={layout}>
        {element}
      </ElementContainer>
    </ErrorBoundary>
  );
};

//组装路由
const _wrapperRoutes = (routes, subRoute = false) => {
  return routes.map((route) => {
    route.element = _wrapperElement(route);

    if (route.children) {
      route.children = _wrapperRoutes(route.children, true);
    }

    return route;
  });
};

//获取路由
const getRouters = (routersConfig) => {
  const _routeConfig = _wrapperRoutes(routersConfig);
  return _routeConfig;
};

const Routers: any = () => {
  let _routes = [
    ...routers,
    {
      path: "500",
      children: [
        {
          index: true,
          element: () => import("@js/components/Error/500"),
        },
      ],
    },
    {
      path: "403",
      children: [
        {
          index: true,
          element: () => import("@js/components/Error/403"),
          authorize: false,
        },
      ],
    },
  ];
  let _withoutLayout = _routes.filter((item) => item.layout === false);

  let _mainLayouts = _routes.filter((item) => item.layout !== false);
  _mainLayouts = _mainLayouts.map((item) => {
    if (item.path === "/") return item;

    if (item.path[0] === "/") {
      return {
        ...item,
        path: item.path.substr(1, item.path.length),
        element: item.element ?? (
          <Suspense fallback={<Spin></Spin>}>
            <Outlet></Outlet>
          </Suspense>
        ),
      };
    }

    return item;
  });

  const _routers = useMemo(
    () => [
      ...getRouters(_withoutLayout),
      {
        path: "/",
        element: (
          <MainLayout Menus={Menus}>
            <Suspense fallback={"loading"}>
              <Outlet></Outlet>
            </Suspense>
          </MainLayout>
        ),
        children: [
          ...[
            <Route
              index
              element={<Navigate to="/" replace></Navigate>}
            ></Route>,
          ],
          ...getRouters(_mainLayouts),
        ],
      },
      {
        path: "*",
        element: <Page404 />,
      },
    ],
    []
  );

  console.log(_routers, "rotuers");
  return useRoutes(_routers);
};

export const Menus = (userInfo) => {
  const filterMenus = (menus, parentPath = "") => {
    return menus.map((item: any) => {
      const _item = { ...item };
      const _hasAuth =
        //@ts-ignore
        process.env.NODE_ENV === "production"
          ? typeof item.code === "undefined" || item.code === userInfo.role
          : true;
      // console.log(userInfo, '---userinfo', _hasAuth, menus)
      if (!_hasAuth) return { ...item, menu: false };

      if (_item.menu === false) return { ...item, menu: false };

      if (_item?.children?.length > 0) {
        //组装子菜单路由
        _item.children = _item.children.map((subItem) => {
          subItem.path = [_item.path, subItem.path].join("/");

          return subItem;
        });

        _item.children = filterMenus(_item.children, _item.path);
        // console.log(_item.children, "children");

        return { ..._item, children: _item.children };
      }

      //是否要验证权限
      return { ...item, menu: true };
    });
  };
  const _router = cloneDeep(routers) as Array<any>;

  let _menus = filterMenus(_router.sort((a, b) => a.sort - b.sort));

  _menus = _menus.filter((item) => item.menu != false);
  // console.log(_menus, "menus");
  return _menus;
};

export { routers as RouterMenus };

export default Routers;
