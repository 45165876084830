import React from "react";
import { RemoteTable } from "@package/table";
import { useLoadTableData } from "@src/javascripts/common/useTableCallback";
import { Image } from "antd";
import { useParams } from "react-router-dom";

const userWorks = () => {
  const param = useParams();

  console.log(param);

  if (!param.userCode) {
    history.back();
    return;
  }

  const _columns = [
    {
      title: "用户ID",
      dataIndex: "userCode",
    },
    {
      title: "生成时间",
      dataIndex: "createTime",
    },
    {
      title: "产品类型",
      dataIndex: "categoryName",
    },
    {
      title: "产品工艺",
      dataIndex: "categoryProcess",
    },
    {
      title: "产品材质",
      dataIndex: "categoryMaterial",
    },
    {
      title: "关键词",
      dataIndex: "keyWord",
    },
    {
      title: "平面图",
      dataIndex: "workKey",
      render: (data, record) => {
        return <Image src={data} alt={record.categoryCode} />;
      },
    },
  ];
  return (
    <>
      <RemoteTable
        columns={_columns}
        loadData={useLoadTableData("user/UserWorks", () => {
          return {
            userCode: param.userCode,
          };
        })}
      ></RemoteTable>
    </>
  );
};

export default userWorks;
