import React, { useId, useImperativeHandle, useRef } from "react";
import { IFormItemProps, ModalForm } from "@package/form";
import { useDispatchAsync } from "@src/javascripts/common/useDispatch";
import { ResponseCode } from "@src/global/layout";
import { Form, Input, message } from "antd";
import { EnumUserRole } from "@src/javascripts/common/enums/EnumRole";
import classNames from "classnames";

const EditUser = (prop, ref) => {
  const _ref = useRef<any>();
  const { table } = prop;

  const _formItems: Array<IFormItemProps> = [
    {
      formItemProps: {
        name: "userId",
        hidden: true,
      },
    },
    {
      formItemProps: {
        name: "userCode",
        hidden: true,
      },
    },
    {
      formItemProps: {
        name: "userStatus",
        hidden: true,
      },
    },
    {
      type: "input",
      formItemProps: {
        name: "username",
        label: "用户名",
      },
      formControlProps: {
        render: (props, ...args) => {
          console.log(props, "--props", args);
          const form = Form.useFormInstance();

          const userId = form.getFieldValue("userId");

          if (!userId) {
            return <Input value={props.value} onChange={props.onChange} />;
          }

          return <>{props.value}</>;
        },
      },
    },
    // {
    //   type: "label",
    //   formItemProps: {
    //     label: "邮箱账号",
    //     name: "email",
    //     hidden: (value) => {
    //       return !value.userId;
    //     },
    //   },
    //   formControlProps: {
    //     render: ({ value, ...restProps }) => {
    //       console.log(value, restProps);
    //       return value;
    //     },
    //   },
    // },
    {
      type: "input",
      formItemProps: {
        label: "邮箱账号",
        name: "email",
      },
      formControlProps: {
        render: (props, ...args) => {
          console.log(props, "--props", args);
          const form = Form.useFormInstance();

          const userId = form.getFieldValue("userId");

          if (!userId) {
            return <Input value={props.value} onChange={props.onChange} />;
          }

          return <>{props.value}</>;
        },
      },
    },
    {
      formItemProps: {
        label: "手机号码",
        name: "tel",
        rules: [{ required: true }],
      },
    },
    {
      type: "group",
      formItemProps: {
        label: "剩余平面图案生成次数",
        className: "pb-[12px]",
        labelCol: { span: 8 },
        rules: [{ required: true }],
      },
      children: [
        {
          type: "label",
          formItemProps: {
            name: ["userBalanceList", 0, "balanceId"],
            hidden: true,
          },
        },
        {
          type: "label",
          formItemProps: {
            name: ["userBalanceList", 0, "detailName"],
            hidden: true,
          },
        },
        {
          type: "label",
          formItemProps: {
            name: ["userBalanceList", 0, "quantity"],
          },
        },
        {
          type: "inputnumber",
          formItemProps: {
            name: ["userBalanceList", 0, "newQuantity"],
          },
        },
      ],
    },
    {
      type: "group",
      formItemProps: {
        labelCol: { span: 8 },
        label: "剩余剩余3D模型文件生成次数",
        className: "pb-[12px]",
        rules: [{ required: true }],
      },
      children: [
        {
          type: "label",
          formItemProps: {
            name: ["userBalanceList", 1, "balanceId"],
            hidden: true,
          },
        },
        {
          type: "label",
          formItemProps: {
            name: ["userBalanceList", 0, "detailName"],
            hidden: true,
          },
        },
        {
          type: "label",
          formItemProps: {
            name: ["userBalanceList", 1, "quantity"],
          },
        },
        {
          type: "inputnumber",
          formItemProps: {
            name: ["userBalanceList", 1, "newQuantity"],
          },
        },
      ],
    },
    {
      type: "section",
      title: "重置密码",
      className: classNames("border-[1px] rounded-[12px]"),
      children: [
        {
          type: "password",
          formItemProps: {
            label: "密码",
            name: "password",
          },
        },
        {
          type: "password",
          formItemProps: {
            label: "确认密码",
            name: "confirmPassword",
            dependencies: ["password"],
            rules: [
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("密码不一致,请确认"));
                },
              }),
            ],
          },
        },
      ],
    },
  ];

  const saveData = async (data) => {
    const { userBalanceList, ...restData } = data;
    const _res = await useDispatchAsync({
      type: data.userId ? "user/UpdateUser" : "user/AddUser",
      data: {
        ...restData,
        userBalanceList: (userBalanceList || []).map((item) => {
          return {
            ...item,
            quantity:
              typeof item.newQuantity != "undefined"
                ? item.newQuantity
                : item.quantity,
          };
        }),
      },
    });

    if (_res.code === ResponseCode.Success) {
      message.success("用户信息保存成功");
      table?.reload?.();
      return;
    }

    return _res.message;
  };

  useImperativeHandle(ref, () => _ref.current, []);

  return (
    <ModalForm
      ref={_ref}
      title="添加/编辑用户"
      formItems={_formItems}
      onOk={saveData}
      width={720}
    ></ModalForm>
  );
};

export default React.forwardRef(EditUser);
