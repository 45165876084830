const routes = [
  {
    path: "/user",
    menuName: "用户管理",
    icon: "UserOutlined",
    code: [1],
    auth: (data) => {
      return data?.userRole != 3;
    },
    sort: 8,
    children: [
      {
        path: "list",
        menuName: "用户列表",
        index: true,
        element: () => import("./userlist"),
      },
      {
        path: "works/:userCode",
        menuName: "用户作品",
        menu: false,
        element: () => import("./userWorks"),
      },
    ],
  },
];

export default routes;
